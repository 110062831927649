// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-training-js": () => import("./../../../src/pages/corporate-training.js" /* webpackChunkName: "component---src-pages-corporate-training-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-website-and-app-tracking-js": () => import("./../../../src/pages/website-and-app-tracking.js" /* webpackChunkName: "component---src-pages-website-and-app-tracking-js" */),
  "component---src-pages-website-audit-and-analysis-js": () => import("./../../../src/pages/website-audit-and-analysis.js" /* webpackChunkName: "component---src-pages-website-audit-and-analysis-js" */),
  "component---src-pages-website-optimization-js": () => import("./../../../src/pages/website-optimization.js" /* webpackChunkName: "component---src-pages-website-optimization-js" */)
}

